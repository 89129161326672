import React, { ReactElement } from 'react';
import pulseLoaderSvg from 'assets/pulse-loader.svg';
import './page-loader.scss';

const PageLoader = (): ReactElement => (
  <div className='page-loader'>
    <img src={pulseLoaderSvg} alt='Loading...' />
  </div>
);

export default PageLoader;
