import React, { lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import {
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  COOKIES_PATH,
  TIME_PATH,
} from 'constants/paths';
import { useRollout } from './contexts/rollout-context';
import MaintenancePage from './screens/maintenance-page/maintenance-page';
import PageLoader from './components/layout/page-loader/page-loader';

// eslint-disable-next-line require-await
const LoginScreen = lazy(async () => import('screens/login'));
// eslint-disable-next-line require-await
const ForgotPassword = lazy(async () => import('screens/forgot-password'));
// eslint-disable-next-line require-await
const ResetPassword = lazy(async () => import('screens/reset-password'));
const CookieInstructions = lazy(
  // eslint-disable-next-line require-await
  async () => import('screens/cookie-instructions/cookie-instructions'),
);
const TimeInstructions = lazy(
  // eslint-disable-next-line require-await
  async () => import('screens/time-instructions/time-instructions'),
);

export default function AppRoutes(): React.ReactElement {
  const { featureFlag, isLoading } = useRollout();

  if (isLoading) return <PageLoader />;
  if (featureFlag.inMaintenance) return <MaintenancePage />;

  return (
    <Switch>
      <Route path={LOGIN_PATH} component={LoginScreen} />
      <Route path={FORGOT_PASSWORD_PATH} component={ForgotPassword} />
      <Route path={RESET_PASSWORD_PATH} component={ResetPassword} />
      <Route path={COOKIES_PATH} component={CookieInstructions} />
      <Route path={TIME_PATH} component={TimeInstructions} />
      <Redirect to={LOGIN_PATH} />
    </Switch>
  );
}
