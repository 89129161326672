import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as Sentry from '@sentry/browser';
import { setupLogger, LogLevel } from '@loanmarket/logger-core';
import {
  CloudWatchLogger,
  SetupCloudWatchOptions,
} from '@loanmarket/logger-cloud-watch';
import { cloudWatchKeyFunctions } from '@loanmarket/logger-cloud-watch/lib/utils/browser-fingerprint';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

// TODO change to be based on environment when all keys set up
if (process.env.REACT_APP_USE_CLOUD_WATCH === 'true') {
  setupLogger<SetupCloudWatchOptions>({
    minLogLevel: LogLevel.Info,
    logger: CloudWatchLogger,
    customLoggerArgs: {
      group: `/aws/frontend/mycrm-react${process.env.REACT_APP_ENV}`,
      userAgent: navigator.userAgent,
      cloudWatchConfiguration: {
        accessKeyId: process.env.REACT_APP_CLOUD_WATCH_LOGS_ACCESS_KEY_ID,
        secretAccessKey:
          process.env.REACT_APP_CLOUD_WATCH_LOGS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_CLOUD_WATCH_LOGS_REGION,
        correctClockSkew: true,
      },
      maxRetries: 3,
      keyFunctions: cloudWatchKeyFunctions,
      newLogStreamPerRequest: true,
    },
  });
} else {
  setupLogger({
    minLogLevel: LogLevel.Trace,
  });
}

ReactDOM.render(<App />, document.querySelector('#root'));
