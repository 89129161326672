import React from 'react';
import mycrmLogo from 'assets/images/mycrm-logo-reverse.png';
import './maintenance-page.scss';

const MaintenancePage = () => {
  return (
    <div className='maintenance-page'>
      <div className='header'>
        <img src={mycrmLogo} alt='Logo' />
      </div>
      <div className='content'>
        <h2>We&apos;ll be back soon!</h2>
        <p>
          This website is currently undergoing maintenance and will be back
          online shortly. Thank you for your patience.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
