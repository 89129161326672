import { getUserInfo } from 'api/mycrm-api';
import { REDIRECT_URLS } from 'constants/paths';
import { OktaAuth } from '@loanmarket/okta-client';

export const redirectUser = async (authClient: OktaAuth): Promise<void> => {
  try {
    const token = await authClient.tokenManager.get('access_token');
    if (token) {
      const { data } = await getUserInfo(token.value);

      if (data !== undefined && !data.IsAu) {
        window.location.replace(REDIRECT_URLS.NZ);
        return;
      }
    }

    window.location.replace(REDIRECT_URLS.AU);
  } catch (error) {
    window.location.replace(REDIRECT_URLS.AU);
  }
};
