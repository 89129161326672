import React, { useEffect, useState, createContext, useContext } from 'react';
import Rox, { Flag } from 'rox-browser';

const ROLLOUT_KEY = process.env.REACT_APP_ROLLOUT_KEY as string;

interface RoxContainer {
  [key: string]: Flag;
}

interface Flags {
  [key: string]: boolean;
}

interface UseRollout {
  featureFlag: RoxContainer;
  isLoading: boolean;
}

const RolloutContext = createContext({});

const getMappedFlags = (flags: RoxContainer) => {
  const mappedFlags: Flags = {};
  Object.keys(flags).forEach((flagKey) => {
    const isEnabled = flags[flagKey].isEnabled();
    mappedFlags[flagKey] = isEnabled;
  });
  return mappedFlags;
};

const rolloutFeatureFlags = {
  inMaintenance: new Rox.Flag(),
};

const RolloutProvider = (
  props: React.PropsWithChildren<{}>,
): React.ReactElement => {
  const [flags, setFlags] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function setupRollout() {
    if (ROLLOUT_KEY == null) {
      return;
    }
    setIsLoading(true);

    Rox.register('', rolloutFeatureFlags);
    await Rox.setup(ROLLOUT_KEY, {
      configurationFetchedHandler: () => {
        const newFlags = getMappedFlags(rolloutFeatureFlags);
        setFlags(newFlags);
        setIsLoading(false);
      },
    });
  }

  useEffect(() => {
    setupRollout();
  }, []);

  const values: UseRollout = {
    featureFlag: flags,
    isLoading,
  };

  return <RolloutContext.Provider value={values} {...props} />;
};

const useRollout = (): UseRollout => useContext(RolloutContext) as UseRollout;

export { RolloutProvider, useRollout };
