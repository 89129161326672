import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_MYCRM_API_DOMAIN;

export const getUserInfo = async (
  token: string,
): Promise<{ data?: { IsAu: boolean }; error?: Error }> => {
  try {
    const result = await axios.get(`${BASE_URL}/GetUserInfo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: result.data };
  } catch (error) {
    return { error };
  }
};
