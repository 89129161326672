import axios from 'axios';

export const getClientInfo = async (): Promise<{
  data?: string;
  error?: Error;
}> => {
  try {
    return await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
  } catch (error) {
    return { error };
  }
};
