export const MYCRM_BASE_PATH = '/#/app/home';
const BASE_PATH = '/app';

export const LOGIN_PATH = `${BASE_PATH}/login`;
export const ENROLL_PATH = `${BASE_PATH}/enroll`;
export const FORGOT_PASSWORD_PATH = `${BASE_PATH}/forgot-password`;
export const RESET_PASSWORD_PATH = `${BASE_PATH}/reset-password`;
export const AUTH_CALLBACK_PATH = `${BASE_PATH}/authorization-code/callback`;
export const COOKIES_PATH = `${BASE_PATH}/cookies-instructions`;
export const TIME_PATH = `${BASE_PATH}/time-instructions`;

export const REDIRECT_URLS = {
  AU: `${process.env.REACT_APP_AU_MYCRM_DOMAIN}${MYCRM_BASE_PATH}`,
  NZ: `${process.env.REACT_APP_NZ_MYCRM_DOMAIN}${MYCRM_BASE_PATH}`,
};
