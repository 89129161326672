import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { AUTH_CALLBACK_PATH } from 'constants/paths';
import 'styles/index.scss';
import PageLoader from 'components/layout/page-loader/page-loader';

import { AuthProvider } from '@loanmarket/okta-client';
import AppRoutes from 'app-routes';
import { RolloutProvider } from 'contexts/rollout-context';
import { LoggerProvider } from 'contexts/logger-provider';
import { redirectUser } from 'helpers/redirect-helper';

const ISSUER = process.env.REACT_APP_OKTA_ISSUER as string;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID as string;
const MYCRM_TOKEN_STORAGE = 'mycrm-tokens';

export default function App(): React.ReactElement {
  return (
    <div className='App'>
      <CookiesProvider>
        <Router>
          <AuthProvider
            issuer={ISSUER}
            passwordResetURL={process.env.REACT_APP_BASE_MYCRM_API_DOMAIN ?? ''}
            clientId={CLIENT_ID}
            redirectUri={`${window.location.origin}${AUTH_CALLBACK_PATH}`}
            tokenManager={{
              storageKey: MYCRM_TOKEN_STORAGE,
            }}
            redirectCallback={redirectUser}
            pkce
            scopes={['openid', 'profile', 'email']}
          >
            <RolloutProvider>
              <LoggerProvider>
                <Suspense fallback={<PageLoader />}>
                  <AppRoutes />
                </Suspense>
              </LoggerProvider>
            </RolloutProvider>
          </AuthProvider>
        </Router>
      </CookiesProvider>
    </div>
  );
}
