export const convertPlainTextToJson = (
  data: string,
  separator: string = '=',
): Dictionary<string> => {
  const sp = data.split('\n');
  return sp.reduce((acc: object, line: string) => {
    const [key, value] = line.split(separator);
    return key === undefined || key === '' ? acc : { ...acc, [key]: value };
  }, {});
};
