import React, { useEffect, useState, createContext, useContext } from 'react';
import { getLogger } from '@loanmarket/logger-core';
import { getClientInfo } from 'api/cloudflare';
import { convertPlainTextToJson } from 'helpers/plain-text-helper';

type LogMessage = Dictionary<string | undefined>;

interface Log {
  info: (data: LogMessage) => void;
  error: (data: LogMessage) => void;
}

interface UseLogger {
  log: Log;
  setActionDetail: (data: LogMessage) => void;
}

const LoggerContext = createContext({});

const LoggerProvider = (
  props: React.PropsWithChildren<{}>,
): React.ReactElement => {
  const logger = getLogger('Okta Authentication');

  const [clientDetails, setClientDetails] = useState<Dictionary<string>>({});

  let actionDetails: Dictionary<string | undefined> = {};

  const getClientDetails = async () => {
    const { data } = await getClientInfo();
    if (data === undefined) return;
    const jsonClientInfo = convertPlainTextToJson(data);
    setClientDetails(jsonClientInfo);
  };

  useEffect(() => {
    getClientDetails();
  }, []);

  const formatLogMessage = (data: LogMessage) => {
    const message = {
      action: actionDetails.action,
      username: actionDetails.username,
      ...data,
      'user-agent': clientDetails.uag,
      ip: clientDetails.ip,
    };
    return JSON.stringify(message);
  };

  const log: Log = {
    info: (data: LogMessage) => logger.info(formatLogMessage(data)),
    error: (data: LogMessage) => logger.error(formatLogMessage(data)),
  };

  const setActionDetail = (data: LogMessage) => {
    actionDetails = { ...actionDetails, ...data };
  };

  const values: UseLogger = {
    log,
    setActionDetail,
  };

  return <LoggerContext.Provider value={values} {...props} />;
};

const useLogger = (): UseLogger => useContext(LoggerContext) as UseLogger;

export { LoggerProvider, useLogger };
